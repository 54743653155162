<template>
  <div>
    <div>
      <div v-for="(item, index) in itemList" :key="index">
        <h2 class="column-title">{{ formatTitle(item) }}</h2>
        <ModuleIteration
          :pageStatus="pageStatus"
          :controlData="mapAttributeModel(item)"
          v-model="formData[item]"
        />
        <el-divider />
      </div>
      <!-- RevisionDate -->
      <h2 class="column-title">RevisionDate</h2>
      <RevisionComponents
        :revisionList="revisionData"
        :pageStatus="pageStatus"
      ></RevisionComponents>

      <el-divider />
      <NotesComponents
        :selectProductType="selectProductType"
        :otherNotesData="otherNotesData"
      ></NotesComponents>
      <!-- 添加Verify打印信息 -->
      <el-divider />
      <div>
        <el-checkbox v-model="isPrintVerify">Print Please Verify</el-checkbox>
      </div>

      <el-divider />
      <!-- 操作按钮 -->
      <OptionsButton
        :pageStatus="pageStatus"
        :isPrintSignature="isPrintVerify"
      />
    </div>
    <el-backtop target=".content" :bottom="100">
      <div>UP</div>
    </el-backtop>
  </div>
</template>

<script>
import ModuleIteration from "./ModuleIteration.vue";
import RevisionComponents from "./components/RevisionComponents.vue";
import OptionsButton from "./OptionsButton.vue";
import NotesComponents from "./components/NotesComponents.vue";

export default {
  props: {
    itemList: Array,
    formData: Object,
    revisionData: Array,
    pageStatus: String,
    selectProductType: String,
    otherNotesData: Object,
    language: String,
  },
  components: {
    ModuleIteration,
    RevisionComponents,
    OptionsButton,
    NotesComponents,
  },
  data() {
    return {
      isPrintVerify: false,
      mappingTitle: [
        { key: "base_info", value: "基础信息" },
        { key: "general_specification", value: "产品信息" },
        { key: "analysis", value: "分析项目" },
        { key: "microbiological_tests", value: "微生物测试" },
        { key: "fatty_acid", value: "脂肪酸" },
        { key: "other_notes", value: "其他信息" },
      ],
    };
  },
  inject: ["getControlDataMethod"],
  methods: {
    formatTitle(str) {
      console.info("str:", str);
      if (this.language == "CN") {
        let title = this.mappingTitle.find(item => item.key == str).value;
        return title;
      }
      return str
        .toLowerCase()
        .replace(/_/g, " ")
        .replace(/\b\w/g, (match) => match.toUpperCase());
    },
    mapAttributeModel(str) {
      let dataAttr =
        str.replace(/_([a-z])/g, (g) => g[1].toUpperCase()) + "Data";
      let data = this.getControlDataMethod(dataAttr);
      return data;
    },
  },
  created() {
    // console.info("this.itemList:", this.itemList);
    // console.info('this.formData:',this.formData)
  },
};
</script>
