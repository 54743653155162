import * as defaultData from "../../data/DefaultData";

export const TY_SHXTO = {
  base_info: {
    product_type: {
      type: "select",
      label: "产品类别",
      tips_value: "",
      options_value: defaultData.CNProductTypeData,
      value: "",
      disabled: false,
      eventHandle: "productTypeChange",
    },
    product_name: {
      type: "input",
      label: "产品名称",
      tips_value: "",
      value: "",
      disabled: false,
    },
    item_code: {
      type: "input",
      label: "产品编码",
      tips_value: "",
      value: "",
      disabled: false,
    },
    product_description: {
      type: "textarea",
      label: "产品说明",
      tips_value: "",
      value: "",
      disabled: false,
    },
    version_number: {
      type: "input",
      label: "版本号",
      tips_value: "",
      value: "",
      disabled: false,
    },
    physical_properties: {
      type: "select",
      label: "物理形态",
      tips_value: "",
      options_value: defaultData.PhysicalPropertiesData,
      value: "",
      disabled: false,
    },
    confidential: {
      type: "checkbox",
      label: "保密文件",
      tips_value: false,
      value: "",
      disabled: false,
      checked: false,
    },
    custom_formula: {
      type: "checkbox",
      label: "客户定制配方",
      tips_value: false,
      value: "",
      disabled: false,
      checked: false,
    },
    document_notes: {
      type: "input",
      label: "文件备注",
      tips_value: "",
      value: "",
      disabled: false,
    },
    custom_field: {
      type: "custom_input",
      label: "自定义字段",
      tips_value: "",
      value: "",
      disabled: false,
      fields: "",
      attr: "custom_field",
    },
  },
  general_specification: {
    common_other_name: {
      type: "tag",
      label: "通用名/其他名称",
      tips_value: [],
      value: "",
      disabled: false,
    },
    inci_name: {
      type: "tag",
      label: "INCI名称",
      tips_value: [],
      value: "",
      disabled: false,
    },
    botanical_name: {
      type: "tag",
      label: "植物学名",
      tips_value: [],
      value: "",
      disabled: false,
    },
    part_used: {
      type: "tag",
      label: "使用部位",
      tips_value: [],
      value: "",
      disabled: false,
    },
    country_of_origin: {
      type: "input",
      label: "原产地",
      tips_value: "中国",
      value: "",
      disabled: false,
    },
    custom_field_before_regular_data: {
      type: "custom_input",
      label: "自定义字段",
      tips_value: "",
      value: "",
      disabled: false,
      fields: "",
      attr: "custom_field_before_regular_data",
    },
    appearance: {
      type: "input",
      label: "外观",
      tips_value: "粘稠油状液体",
      value: "",
      disabled: false,
    },
    other_ingredients: {
      type: "input",
      label: "其他成分",
      tips_value: "",
      value: "",
      disabled: false,
    },
    custom_field: {
      type: "custom_input",
      label: "自定义字段",
      tips_value: "",
      value: "",
      disabled: false,
      fields: "",
      attr: "gs_custom_field",
    },
  },
  analysis: {
    acid_value: {
      type: "input",
      label: "酸值",
      tips_value: {
        specification: "NMT 10 mgKOH/g",
        test_method: "AOCS Cd 3d-63",
      },
      value: "",
      disabled: false,
    },
    peroxide_value: {
      type: "input",
      label: "过氧化值",
      tips_value: {
        specification: "NMT 10.0 meq/kg",
        test_method: "AOCS Cd 8b-90",
      },
      value: "",
      disabled: false,
    },
    saponification_value: {
      type: "input",
      label: "皂化值",
      tips_value: { specification: "mg KOH/g", test_method: "AOCS Cd 3-25" },
      value: "",
      disabled: false,
    },
    unsaponifiable_master: {
      type: "input",
      label: "不皂化物含量",
      tips_value: { specification: "%", test_method: "AOCS Ca 6a-40" },
      value: "",
      disabled: false,
    },
    moisture_volatiles: {
      type: "input",
      label: "水分和挥发物含量",
      tips_value: { specification: "%", test_method: "AOCS Ca 2d-25" },
      value: "",
      disabled: false,
    },
    total_heavy_metals: {
      type: "input",
      label: "重金属",
      tips_value: { specification: "<10 ppm", test_method: "USP<233>" },
      value: "",
      disabled: false,
    },
    lead: {
      type: "input",
      label: "铅",
      tips_value: { specification: "<1.0 ppm", test_method: "AOCS Ca 18c-91" },
      value: "",
      disabled: false,
    },
    arsenic: {
      type: "input",
      label: "砷",
      tips_value: { specification: "<1.0 ppm", test_method: "AOAC 986.15" },
      value: "",
      disabled: false,
    },
    cadmium: {
      type: "input",
      label: "镉",
      tips_value: { specification: "<1.0 ppm", test_method: "AOAC 986.15" },
      value: "",
      disabled: false,
    },
    mercury: {
      type: "input",
      label: "汞",
      tips_value: { specification: "<1.0 ppm", test_method: "AOAC 971.21" },
      value: "",
      disabled: false,
    },
    pesticides: {
      type: "input",
      label: "农药残留",
      tips_value: { specification: "符合USP<565>", test_method: "USP<565>" },
      value: "",
      disabled: false,
    },
    color: {
      type: "input",
      label: "颜色",
      tips_value: { specification: "标准", test_method: "视觉" },
      value: "",
      disabled: false,
    },
    odor: {
      type: "input",
      label: "气味",
      tips_value: { specification: "符合标准", test_method: "感官评定" },
      value: "",
      disabled: false,
    },
    taste: {
      type: "input",
      label: "味道",
      tips_value: { specification: "符合标准", test_method: "感官评定" },
      value: "",
      disabled: false,
    },
    custom_field: {
      type: "custom_input",
      label: "自定义字段",
      tips_value: { specification: "", test_method: "" },
      value: "",
      disabled: false,
      fields: "",
      attr: "an_custom_field",
    },
  },
  fatty_acid: {
    caprylic: {
      type: "input",
      label: "C8:0 辛酸",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
    },
    capric: {
      type: "input",
      label: "C10:0 癸酸",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
    },
    lauric: {
      type: "input",
      label: "C12:0 月桂酸",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
    },
    myristic: {
      type: "input",
      label: "C14:0 豆蔻酸",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
    },
    myristoleic: {
      type: "input",
      label: "C14:1 豆蔻油酸",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
    },
    palmitica: {
      type: "input",
      label: "C16:0 棕榈酸",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
    },
    palmitoleic: {
      type: "input",
      label: "C16:1 棕榈油酸",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
    },
    stearic: {
      type: "input",
      label: "C18:0 硬脂酸",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
    },
    oleic: {
      type: "input",
      label: "C18:1 油酸",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
    },
    linoleic_acid: {
      type: "input",
      label: "linoleic_acid",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
    },
    a_linolenic: {
      type: "input",
      label: "C18:3 α-亚麻酸",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
    },
    y_linoleic: {
      type: "input",
      label: "C18:3 γ-亚麻酸",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
    },
    punicic_acid: {
      type: "input",
      label: "punicic_acid",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
    },
    arachidic: {
      type: "input",
      label: "C20:0 花生酸/AA",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
    },
    arachidonic: {
      type: "input",
      label: "C20:4 花生四烯酸",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
    },
    eicosapentaenoic: {
      type: "input",
      label: "C20:5 二十碳五烯酸/EPA",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
    },
    docosahexaenoic: {
      type: "input",
      label: "C22:6 二十二碳六烯酸/DHA",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
    },
    others: {
      type: "input",
      label: "其它",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
    },
    custom_field: {
      type: "custom_input",
      label: "自定义字段",
      tips_value: { specification: "", test_method: "" },
      value: "",
      disabled: false,
      fields: "",
      attr: "fa_custom_field",
    },
  },
  microbiological_tests: {
    total_plate_count: {
      type: "input",
      label: "细菌总数",
      tips_value: { specification: "", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
    },
    yeast_and_mold: {
      type: "input",
      label: "酵母和霉菌",
      tips_value: { specification: "", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
    },
    ecoli: {
      type: "input",
      label: "大肠杆菌",
      tips_value: { specification: "", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
    },
    salmonella: {
      type: "input",
      label: "沙门氏菌",
      tips_value: { specification: "", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
    },
    staph_aureus: {
      type: "input",
      label: "金色葡萄球菌",
      tips_value: { specification: "", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
    },
    total_coliforms: {
      type: "input",
      label: "大肠菌群总数",
      tips_value: { specification: "", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
    },
    custom_field: {
      type: "custom_input",
      label: "自定义字段",
      tips_value: { specification: "", test_method: "" },
      value: "",
      disabled: false,
      fields: "",
      attr: "mt_custom_field",
    },
  },
  other_notes: {
    shelflife_number: {
      type: "input",
      label: "保质期(*个月)",
      tips_value: "",
      value: "",
      disabled: false,
    },
    shelf_life: {
      type: "input",
      label: "保质期备注",
      tips_value: "",
      value: "",
      disabled: false,
    },
    storage: {
      type: "input",
      label: "储存条件",
      tips_value: "避光，密封，在干燥处保存。",
      value: "",
      disabled: false,
    },
    package_info: {
      type: "input",
      label: "包装",
      tips_value: "",
      value: "",
      disabled: false,
    },
    is_show_note: {
      type: "input",
      label: "is_show_note",
      tips_value: false,
      value: "",
      disabled: false,
    },
    notes: {
      type: "input",
      label: "备注",
      tips_value: "",
      value: "",
      disabled: false,
    },
  },
};
