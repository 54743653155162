import * as defaultData from "../../data/DefaultData";

export const TY_L = {
  base_info: {
    product_type: {
      type: "select",
      label: "产品类别",
      tips_value: "",
      options_value: defaultData.CNProductTypeData,
      value: "",
      disabled: false,
      eventHandle: "productTypeChange",
    },
    product_name: {
      type: "input",
      label: "产品名称",
      tips_value: "",
      value: "",
      disabled: false,
    },
    item_code: {
      type: "input",
      label: "产品编码",
      tips_value: "",
      value: "",
      disabled: false,
    },
    version_number: {
      type: "input",
      label: "版本号",
      tips_value: "",
      value: "",
      disabled: false,
    },
    confidential: {
      type: "checkbox",
      label: "保密文件",
      tips_value: false,
      value: "",
      disabled: false,
      checked: false,
    },
    custom_formula: {
      type: "checkbox",
      label: "客户定制配方",
      tips_value: false,
      value: "",
      disabled: false,
      checked: false,
    },
    product_description: {
      type: "textarea",
      label: "产品说明",
      tips_value:
        "液体植物提取物中的活性物质来自于本公司特有的控温封闭提取系统，其中大多数生物活性化合物被完全保留到产品中。本提取液100%从天然植物提取。",
      value: "",
      disabled: false,
    },
    physical_properties: {
      type: "select",
      label: "物理形态",
      tips_value: "",
      options_value: defaultData.PhysicalPropertiesData,
      value: "",
      disabled: false,
    },
    document_notes: {
      type: "input",
      label: "文件备注",
      tips_value: "",
      value: "",
      disabled: false,
    },
    custom_field: {
      type: "custom_input",
      label: "自定义字段",
      tips_value: "",
      value: "",
      disabled: false,
      fields: "",
      attr: "custom_field",
    },
  },
  general_specification: {
    common_other_name: {
      type: "tag",
      label: "通用名/其他名称",
      tips_value: [],
      value: "",
      disabled: false,
    },
    inci_name: {
      type: "tag",
      label: "INCI名称",
      tips_value: [],
      value: "",
      disabled: false,
    },
    botanical_name: {
      type: "tag",
      label: "植物学名",
      tips_value: [],
      value: "",
      disabled: false,
    },
    major_constituents_in_native_herbs: {
      type: "input",
      label: "植物主要成分",
      tips_value: "",
      value: "",
      disabled: false,
    },
    part_used: {
      type: "tag",
      label: "使用部位",
      tips_value: [],
      value: "",
      disabled: false,
    },
    country_of_origin: {
      type: "input",
      label: "原产地",
      tips_value: "中国",
      value: "",
      disabled: false,
    },
    custom_field_before_regular_data: {
      type: "custom_input",
      label: "自定义字段",
      tips_value: "",
      value: "",
      disabled: false,
      fields: "",
      attr: "custom_field_before_regular_data",
    },
    percentage: {
      type: "tag",
      label: "百分比",
      tips_value: [],
      value: "",
      disabled: false,
    },
    appearance: {
      type: "input",
      label: "外观",
      tips_value: "Liquid",
      value: "",
      disabled: false,
    },
    solvent: {
      type: "input",
      label: "溶剂",
      tips_value: "",
      value: "",
      disabled: false,
    },
    preservative: {
      type: "input",
      label: "防腐剂",
      tips_value: "",
      value: "",
      disabled: false,
    },
    other_ingredients: {
      type: "input",
      label: "其他成分",
      tips_value: "",
      value: "",
      disabled: false,
    },
    custom_field: {
      type: "custom_input",
      label: "自定义字段",
      tips_value: "",
      value: "",
      disabled: false,
      fields: "",
      attr: "gs_custom_field",
    },
  },
  analysis: {
    bioactives_level: {
      type: "input",
      label: "活性成分控制指标",
      tips_value: { specification: "", test_method: "" },
      value: "",
      disabled: false,
    },
    ph: {
      type: "input",
      label: "pH",
      tips_value: { specification: "3.5-7.5", test_method: "pH meter" },
      value: "",
      disabled: false,
    },
    total_heavy_metals: {
      type: "input",
      label: "重金属",
      tips_value: { specification: "<20 ppm", test_method: "USP<233>" },
      value: "",
      disabled: false,
    },
    arsenic: {
      type: "input",
      label: "砷",
      tips_value: { specification: "<1.0 ppm", test_method: "USP<232>" },
      value: "",
      disabled: false,
    },
    cadmium: {
      type: "input",
      label: "镉",
      tips_value: { specification: "<1.0 ppm", test_method: "USP<232>" },
      value: "",
      disabled: false,
    },
    lead: {
      type: "input",
      label: "铅",
      tips_value: { specification: "<1.0 ppm", test_method: "USP<232>" },
      value: "",
      disabled: false,
    },
    mercury: {
      type: "input",
      label: "汞",
      tips_value: { specification: "<1.0 ppm", test_method: "USP<232>" },
      value: "",
      disabled: false,
    },
    pesticides: {
      type: "input",
      label: "农药残留",
      tips_value: { specification: "符合USP<565>", test_method: "USP<565>" },
      value: "",
      disabled: false,
    },
    color: {
      type: "input",
      label: "颜色",
      tips_value: { specification: "标准", test_method: "视觉" },
      value: "",
      disabled: false,
    },
    odor: {
      type: "input",
      label: "气味",
      tips_value: { specification: "天然淡植物气味", test_method: "感官评定" },
      value: "",
      disabled: false,
    },
    taste: {
      type: "input",
      label: "味道",
      tips_value: { specification: "符合标准", test_method: "感官评定" },
      value: "",
      disabled: false,
    },
    custom_field: {
      type: "custom_input",
      label: "自定义字段",
      tips_value: { specification: "", test_method: "" },
      value: "",
      disabled: false,
      fields: "",
      attr: "an_custom_field",
    },
  },
  microbiological_tests: {
    total_plate_count: {
      type: "input",
      label: "细菌总数",
      tips_value: { specification: "<100 cfu/g", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
    },
    yeast_and_mold: {
      type: "input",
      label: "酵母和霉菌",
      tips_value: { specification: "<100 cfu/g", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
    },
    ecoli: {
      type: "input",
      label: "大肠杆菌",
      tips_value: { specification: "", test_method: "FDA-BAM " },
      value: "",
      disabled: false,
    },
    salmonella: {
      type: "input",
      label: "沙门氏菌",
      tips_value: { specification: "", test_method: "FDA-BAM " },
      value: "",
      disabled: false,
    },
    staph_aureus: {
      type: "input",
      label: "金色葡萄球菌",
      tips_value: { specification: "", test_method: "FDA-BAM " },
      value: "",
      disabled: false,
    },
    total_coliforms: {
      type: "input",
      label: "大肠菌群总数",
      tips_value: { specification: "", test_method: "FDA-BAM " },
      value: "",
      disabled: false,
    },
    custom_field: {
      type: "custom_input",
      label: "自定义字段",
      tips_value: { specification: "", test_method: "" },
      value: "",
      disabled: false,
      fields: "",
      attr: "mt_custom_field",
    },
  },
  other_notes: {
    shelflife_number: {
      type: "input",
      label: "保质期(*个月)",
      tips_value: "",
      value: "",
      disabled: false,
    },
    shelf_life: {
      type: "input",
      label: "保质期备注",
      tips_value: "产品对热敏感。",
      value: "",
      disabled: false,
    },
    storage: {
      type: "input",
      label: "储存条件",
      tips_value: "避光，密封保存",
      value: "",
      disabled: false,
    },
    package_info: {
      type: "input",
      label: "包装",
      tips_value: "",
      value: "",
      disabled: false,
    },
    is_show_note: {
      type: "input",
      label: "is_show_note",
      tips_value: false,
      value: "",
      disabled: false,
    },
    notes: {
      type: "input",
      label: "备注",
      tips_value: "",
      value: "",
      disabled: false,
    },
  },
};
