import * as defaultData from "../../data/DefaultData";

export const TY_EHXTO = {
  base_info: {
    product_type: {
      type: "select",
      label: "产品类别",
      tips_value: "",
      options_value: defaultData.CNProductTypeData,
      value: "",
      disabled: false,
      eventHandle: "productTypeChange",
    },
    product_name: {
      type: "input",
      label: "产品名称",
      tips_value: "",
      value: "",
      disabled: false,
    },
    item_code: {
      type: "input",
      label: "产品编码",
      tips_value: "",
      value: "",
      disabled: false,
    },
    version_number: {
      type: "input",
      label: "版本号",
      tips_value: "",
      value: "",
      disabled: false,
    },
    product_description: {
      type: "textarea",
      label: "产品说明",
      tips_value: "",
      value: "",
      disabled: false,
    },
    physical_properties: {
      type: "select",
      label: "物理形态",
      tips_value: "",
      options_value: defaultData.PhysicalPropertiesData,
      value: "",
      disabled: false,
    },
    confidential: {
      type: "checkbox",
      label: "保密文件",
      tips_value: false,
      value: "",
      disabled: false,
      checked: false,
    },
    custom_formula: {
      type: "checkbox",
      label: "客户定制配方",
      tips_value: false,
      value: "",
      disabled: false,
      checked: false,
    },
    document_notes: {
      type: "input",
      label: "文件备注",
      tips_value: "",
      value: "",
      disabled: false,
    },
    custom_field: {
      type: "custom_input",
      label: "自定义字段",
      tips_value: "",
      value: "",
      disabled: false,
      fields: "",
      attr: "custom_field",
    },
  },
  general_specification: {
    common_other_name: {
      type: "tag",
      label: "通用名/其他名称",
      tips_value: [],
      value: "",
      disabled: false,
    },
    inci_name: {
      type: "tag",
      label: "INCI名称",
      tips_value: [],
      value: "",
      disabled: false,
    },
    botanical_name: {
      type: "tag",
      label: "植物学名",
      tips_value: [],
      value: "",
      disabled: false,
    },
    part_used: {
      type: "tag",
      label: "使用部位",
      tips_value: [],
      value: "",
      disabled: false,
    },
    country_of_origin: {
      type: "input",
      label: "原产地",
      tips_value: "中国",
      value: "",
      disabled: false,
    },
    custom_field_before_regular_data: {
      type: "custom_input",
      label: "自定义字段",
      tips_value: "",
      value: "",
      disabled: false,
      fields: "",
      attr: "custom_field_before_regular_data",
    },
    appearance: {
      type: "input",
      label: "外观",
      tips_value: "粘稠油状液体",
      value: "",
      disabled: false,
    },
    solubility: {
      type: "input",
      label: "溶解度",
      tips_value: "可溶于油脂类",
      value: "",
      disabled: false,
    },
    important_constitue_in_plant: {
      type: "input",
      label: "植物主要成分",
      tips_value: "",
      value: "",
      disabled: false,
    },
    homogeneity: {
      type: "input",
      label: "均匀度",
      tips_value: "完全均匀",
      value: "",
      disabled: false,
    },
    extraction_process: {
      type: "input",
      label: "提取过程",
      tips_value: "超临界二氧化碳提取",
      value: "",
      disabled: false,
    },
    solvent_for_extraction: {
      type: "input",
      label: "提取溶剂",
      tips_value: "二氧化碳",
      value: "",
      disabled: false,
    },
    solvent: {
      type: "input",
      label: "溶剂",
      tips_value: "",
      value: "",
      disabled: false,
    },
    other_ingredients: {
      type: "input",
      label: "其他成分",
      tips_value: "",
      value: "",
      disabled: false,
    },
    pesticides: {
      type: "input",
      label: "农药残留",
      tips_value: "符合USP<565>",
      value: "",
      disabled: false,
    },
    certification: {
      type: "input",
      label: "证书",
      tips_value: "Kosher certified, HALAL certified",
      value: "",
      disabled: false,
    },
    custom_field: {
      type: "custom_input",
      label: "自定义字段",
      tips_value: "",
      value: "",
      disabled: false,
      fields: "",
      attr: "gs_custom_field",
    },
  },
  analysis: {
    moisture: {
      type: "input",
      label: "水分含量",
      tips_value: { specification: "%", test_method: "USP<921>" },
      value: "",
      disabled: false,
    },
    specifica_gravity: {
      type: "input",
      label: "比重",
      tips_value: {
        specification: "1.0200~1.0430 g/mL",
        test_method: "USP<841>",
      },
      value: "",
      disabled: false,
    },
    refractive_index: {
      type: "input",
      label: "折光率",
      tips_value: { specification: "1.5750~1.6010", test_method: "USP<831>" },
      value: "",
      disabled: false,
    },
    optical_rotation: {
      type: "input",
      label: "旋光度",
      tips_value: { specification: "-3°~+1°", test_method: "USP<781>" },
      value: "",
      disabled: false,
    },
    total_heavy_metals: {
      type: "input",
      label: "重金属",
      tips_value: { specification: "<10 ppm", test_method: "USP<233>" },
      value: "",
      disabled: false,
    },
    cadmium: {
      type: "input",
      label: "镉",
      tips_value: { specification: "<0.5 ppm", test_method: "AOAC 986.15" },
      value: "",
      disabled: false,
    },
    lead: {
      type: "input",
      label: "铅",
      tips_value: { specification: "<0.5 ppm", test_method: "AOCS Ca 18c-91" },
      value: "",
      disabled: false,
    },
    mercury: {
      type: "input",
      label: "汞",
      tips_value: { specification: "<0.5 ppm", test_method: "AOAC 971.21" },
      value: "",
      disabled: false,
    },
    arsenic: {
      type: "input",
      label: "砷",
      tips_value: { specification: "<0.5 ppm", test_method: "AOAC 986.15" },
      value: "",
      disabled: false,
    },
    color: {
      type: "input",
      label: "颜色",
      tips_value: { specification: "标准", test_method: "视觉" },
      value: "",
      disabled: false,
    },
    odor: {
      type: "input",
      label: "气味",
      tips_value: { specification: "符合标准", test_method: "感官评定" },
      value: "",
      disabled: false,
    },
    taste: {
      type: "input",
      label: "味道",
      tips_value: { specification: "符合标准", test_method: "感官评定" },
      value: "",
      disabled: false,
    },
    custom_field: {
      type: "custom_input",
      label: "自定义字段",
      tips_value: { specification: "", test_method: "" },
      value: "",
      disabled: false,
      fields: "",
      attr: "an_custom_field",
    },
  },
  microbiological_tests: {
    total_plate_count: {
      type: "input",
      label: "细菌总数",
      tips_value: { specification: "", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
    },
    yeast_and_mold: {
      type: "input",
      label: "酵母和霉菌",
      tips_value: { specification: "", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
    },
    ecoli: {
      type: "input",
      label: "大肠杆菌",
      tips_value: { specification: "", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
    },
    salmonella: {
      type: "input",
      label: "沙门氏菌",
      tips_value: { specification: "", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
    },
    staph_aureus: {
      type: "input",
      label: "金色葡萄球菌",
      tips_value: { specification: "", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
    },
    total_coliforms: {
      type: "input",
      label: "大肠菌群总数",
      tips_value: { specification: "", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
    },
    custom_field: {
      type: "custom_input",
      label: "自定义字段",
      tips_value: { specification: "", test_method: "" },
      value: "",
      disabled: false,
      fields: "",
      attr: "mt_custom_field",
    },
  },
  other_notes: {
    shelflife_number: {
      type: "input",
      label: "保质期(*个月)",
      tips_value: "",
      value: "",
      disabled: false,
    },
    shelf_life: {
      type: "input",
      label: "保质期备注",
      tips_value: "",
      value: "",
      disabled: false,
    },
    storage: {
      type: "input",
      label: "储存条件",
      tips_value: "避光，密封，在干燥处保存",
      value: "",
      disabled: false,
    },
    package_info: {
      type: "input",
      label: "包装",
      tips_value: "",
      value: "",
      disabled: false,
    },
    is_show_note: {
      type: "input",
      label: "is_show_note",
      tips_value: false,
      value: "",
      disabled: false,
    },
    notes: {
      type: "input",
      label: "备注",
      tips_value: "",
      value: "",
      disabled: false,
    },
  },
};
