export const ProductTypeData = [
  { "label": "CSFMU-S" },
  { "label": "CSFMU-B" },
  { "label": "F&V" },
  { "label": "FMU-D" },
  { "label": "HEXT" },
  { "label": "PCG" },
  { "label": "REXT" },
  { "label": "OEXT" },
  { "label": "PCG-CSFMU-S" },
  { "label": "PCG-CSFMU-B" },
  { "label": "SHXTO" },
  { "label": "EHXTO" },
  { "label": "Liquid EXT-S" },
  { "label": "Liquid EXT-B" },
  { "label": "SDNP" },
  { "label": "SDNP-L" }
];

export const CNProductTypeData = [
  { "label": "TY-EHXTO" },
  { "label": "TY-Y" },
  { "label": "TY-P" },
  { "label": "TY-SHXTO" }
];

export const PhysicalPropertiesData = [
  { "label": "Blend" },
  { "label": "Single" },
  { "label": "Granulate" },
  { "label": "Liquid" },
  { "label": "Oil" },
  { "label": "Essential oil" },
  { "label": "Glycerin" }
];

export const MeshSizeOptionsData = [
  { "label": "80-100 Mesh" },
  { "label": "20 Mesh" },
  { "label": "10 Mesh" },
  { "label": ">95% pass 80 Mesh" }
]

export const CarrierOptionsData = [
  { "label": "None" },
  { "label": "Maltodextrin" }
]

export const AppearanceOptionsData = [
  { "label": "Powder" },
  { "label": "Granular" }
]

export const GeneralSpecificationColorData = [
  { label: "Light brown" },
  { label: "Light yellow to yellow" },
  { label: "Light yellow to brownisn yellow" },
  { label: "Light reddish brown to dark brownish red" },
  { label: "Red" }
]

export const ShelflifeNumberData = [
  { label: "12" },
  { label: "18" },
  { label: "24" },
  { label: "36" },
]

export const NotesDefaultData = [
  {
    type: ["HEXT", "F&V", "CSFMU-B", "CSFMU-S", "FMU-D", "PCG", "PCG-CSFMU-S", "PCG-CSFMU-B", "OEXT", "REXT", "SDNP"],
    content: ["* Color variation from batch to batch of the product may occur due to natural variations of raw material.",
      "* The pH of powder product should be tested in its 1% aqueous solution if necessary."]
  },
  {
    type: ["SHXTO", "EHXTO"],
    content: ["* Color variation from batch to batch of the product may occur due to natural variations of raw material.",
      "* Some natural sediment may occur upon standing, that does not compromise the quality of the product; please warm to ambient temperature and mix well before use in such case."]
  },
  {
    type: ["Liquid EXT-S", "Liquid EXT-B", "SDNP-L"],
    content: ["Note: * Color variation from batch to batch of the product may occur due to natural variations of raw material.",
      "* Some natural sediment may occur upon standing, that does not compromise the quality of the product; please warm to ambient temperature and mix well before use in such case.",
      "* The pH of liquid product should be tested in its 1% aqueous solution if the liquid product contains less than 50% water."]
  }
]

export const CNNotesDefaultData = [
  {
    type: ["TY-P"],
    content: ["* 不同批次产品的颜色可能会因天然原材料的自然变化而产生差异。",
      "* 产品含抗氧化化合物，可能会随着时间推移颜色变深但不影响使用效果。（可选打印）",
      "* 必要情况下，产品的pH 值应该在1%该产品的水溶液中测定。"]
  }, {
    type: ["TY-L"],
    content: ["* 不同批次产品的颜色可能会因天然原材料的自然变化而产生差异。",
      "* 产品含抗氧化化合物，可能会随着时间推移颜色变深但不影响使用效果。（可选打印）",
      "* 天然植物产品在长期静置时可能会产生一些产品本身的沉淀物，这不会影响产品的质量；如发现产品有沉淀，请在使用前适当加热并搅拌至充分混合。",
      "* 如果产品中含水量低于50%，则产品的 pH 值必须在1%产品的水溶液中测定。"]
  }, {
    type: ["TY-EHXTO", "TY-SHXTO"],
    content: ["* 不同批次产品的颜色可能会因天然原材料的自然变化而产生差异。",
      "* 产品含抗氧化化合物，可能会随着时间推移颜色变深但不影响使用效果。（可选打印）",
      "* 天然植物产品在长期静置时可能会产生一些产品本身的沉淀物，这不会影响产品的质量；如发现产品有沉淀，请在使用前适当加热并搅拌至充分混合。"]
  }
]