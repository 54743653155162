<template>
  <div>
    <el-input
      v-model="formattedValue"
      :placeholder="'Please Input ' + formatTitle(item.label)"
      :disabled="item.disabled"
      clearable
      @change="inputChange()"
      :style="getStyle()"
    >
      <template v-if="showControl(item)" #suffix>
        <el-popover
          placement="top-start"
          :content="item.tips_value"
          trigger="hover"
          style="text-align: center"
        >
          <el-button
            slot="reference"
            icon="el-icon-edit"
            size="small"
            type="primary"
            :disabled="item.disabled"
            @click="defaultValueHandle(item)"
            >Default</el-button
          >
        </el-popover>
      </template>
    </el-input>
    <el-button v-if="getShowControl()" type="primary" >Complete</el-button>
  </div>
</template>

<script>
import * as StructConvertUtils from "../config/structConvertUtils";
export default {
  props: {
    item: Object,
    controlData: Object,
    direction:String,
    pageStatus:String
  },
  data() {
    return {
      formattedValue: "",
      defaultStyle:"width: 100%",
      notesStyle:"width:95%"
    };
  },
  watch: {
    item: {
      handler(newItem) {
       
        if(this.pageStatus =='Add'){
          return "";
        }
        // 如果 value 是数组，进行类型转换
        this.formattedValue = Array.isArray(newItem.value)
          ? newItem.value.join(", ") // 如果是数组，转化为字符串
          : newItem.value; // 否则使用原来的值
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    inputChange(){
      this.item.value=this.formattedValue;
    },
    getStyle(){
      if(this.getShowControl()){
        return this.notesStyle;
      }else{
        return this.defaultStyle;
      }
    },
    getShowControl() {
      let flag = this.item.label == "Notes";
      return flag;
    },
    showDefaultButton() {},
    showControl(item) {
      return item?.tips_value?.length > 0;
    },
    defaultValueHandle(item) {
      this.item.value = item.tips_value;
      this.$forceUpdate();
    },
    formatTitle(title) {
      let resTitle = StructConvertUtils.formatTitle(title);
      return resTitle;
    },
    shelfLifeTipsSetup() {
      if (this.item.label == "Shelf Life") {
        let tips_value =
          this.controlData.list.shelflife_number.value +
          " months unopened " +
          this.item.tips_value;
        this.item.tips_value = tips_value;
      }
    },
  },
  created() {
    this.shelfLifeTipsSetup();
  },
};
</script>
